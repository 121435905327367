import { dropdownToggle } from '../../utils/utils';
import './mainLayout.scss';

(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const burger = document.querySelector('.js-burger');
        const mobileMenu = document.querySelector('.js-header-mobile-menu');

        const toggleMenu = () => {
            dropdownToggle(mobileMenu);
            burger.classList.toggle('burger--active');
        };
        burger.addEventListener('click', toggleMenu);
        console.log('mainLayout');
    });
})();
